/* First layer of contact page */
.FirstLayer {
    padding: 4%;
    text-align: center;
}

/* Change margin of h1 and h3 */
.FirstLayer h1, h3 {
    margin: 4%;
}

input {
    margin: 0.5%;
}

label {
    margin: 1.5%;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
}

.formName, .formEmail, .formMessage {
    padding: 2%;
}

/* Change margin of the form */
.FirstLayer-form {
    margin: 5%;
}

/* Change margin of the button */
.FirstLayer-button {
    margin: 4%;
}