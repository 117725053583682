.technologies {
  background-color: #e4e2e2;
  padding: 2%;
  text-align: center;
  font-style: "Times New Roman", Times, serif;
}

/* Technology images */
.images {
  padding: 1%;
}

/* Padding between images */
.images img {
  padding: 1%;
}
