/* General styling for the footer */
.footer {
    background: #272727;
    padding: 2%;
    color: white;
}

/* Align all content in footer to the center */
.footer-content {
    text-align: center;
}

.footer-content h4 {
    padding: 10px;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
}