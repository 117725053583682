.septfirst {
  text-align: center;
  padding-bottom: 3%;
  padding-top: 5%;
  height: auto;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
}

.septfirst_info {
  font-size: 20px;
}
