/* First layer of the about me page */
.firstLayer {
  background-color: #e4e2e2;
  padding: 3%;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
}

/* Images of the first layer of the about me page */
.firstLayer img {
  margin: 5.5%;
}

/* Paragraph of the first layer of the about me page */
.firstLayer p {
  font-size: 30px;
}

.logo-about {
  border-radius: 50%;
}
