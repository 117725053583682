/* Number systems project main styling */
.NumberSystems {
    padding: 3%;
    text-align: center;
    font-style: 'Times New Roman', Times, serif;;
    background-color: #ffffff;
}

/* Add padding */
.NumberSystems h4 {
    padding: 2%;
}

/* Change size and center paragraphs */
.NumberSystems p {
    text-align: center;
    font-size: 19px;
}